import { Outlet } from "react-router-dom";
import { Navigation } from "../components/Navigation";
import { Footer } from "../components/Footer";
import ScrollToTop from "../hooks/ScrollToTop";

export const Layout = () => {
  return (
    <div>
      <ScrollToTop />

      <Navigation />

      <Outlet />

      <Footer />
    </div>
  );
};
