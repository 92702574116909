import "./button.css";
import { LoadingAnimation } from "./LoadingAnimation";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  className,
  buttonStyle,
  backgroundColor,
  size = "medium",
  label,
  icon,
  iconClass,
  disabled,
  loading,
  outlined,
  rightIcon,
  align,
  animateIcon = false,
  ...props
}) => {
  const classes = [
    "button group",
    `button--${size}`,
    `button--${buttonStyle}`,
    className,
  ].join(" ");

  let iconElement;
  if (icon) {
    iconElement = icon;
  }

  return (
    <button
      type="button"
      className={classes}
      style={backgroundColor && { backgroundColor: backgroundColor }}
      disabled={disabled || loading}
      {...props}
    >
      <div
        className={`grid grid-flow-col gap-2 justify-center items-center transition ease-in-out group-active:scale-95 relative ${align}`}
      >
        {loading && (
          <LoadingAnimation small spinnerClass="fill-white text-scale-1200" />
        )}
        {!rightIcon && iconElement}
        {label && (
          <div className={`${loading ? "opacity-0" : "opacity-100"}`}>
            {label}
          </div>
        )}
        {rightIcon && iconElement}
      </div>
    </button>
  );
};
