import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";
import { useState, Fragment } from "react";
import { PRODUCT_SURVEY } from "../util/products";
import { Transition, Dialog } from "@headlessui/react";
import { Button } from "../components/Common/Button";
import { useNavigate } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import maintenanceImage from "../assets/maintenance.png";
import repairImage from "../assets/repair.png";
import freightImage from "../assets/freight.png";
import sparePartsImage from "../assets/spare_parts.png";

export const ProductsPage = () => {
  const navigate = useNavigate();
  const [currentOption, setCurrentOption] = useState(PRODUCT_SURVEY.options[0]);
  const [sliderValue, setSliderValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  return (
    <div>
      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-14 md:py-24 space-y-12 px-5 md:px-0">
        <div className="flex flex-col items-center gap-4">
          <div className="text-5xl font-bold">Product Finder</div>
          <div className="text-gray-500 text-center">
            Help us find the perfect product for you and your project.
          </div>
        </div>

        {currentOption.options && (
          <div className="flex flex-col items-center gap-4">
            <div className="font-medium text-xl">{currentOption.question}</div>
            <div
              className="grid items-center gap-3 md:gap-5 text-center"
              style={{
                gridTemplateColumns: `repeat(${
                  currentOption?.options?.length ?? 0
                }, minmax(0, 1fr))`,
              }}
            >
              {currentOption.options?.map((option, index) => (
                <div
                  key={index}
                  className="cursor-pointer flex items-center justify-center py-5 px-7 border hover:border-primary hover:bg-primary/20 rounded-md bg-gray-50 transition ease-in-out font-semibold"
                  onClick={() => setCurrentOption(option)}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        )}

        <Transition
          show={!!currentOption.products}
          enter="transition duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave=""
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="grid grid-cols-12 gap-8 max-w-7xl w-full">
            <div className="col-span-3">
              <div className="rounded-lg bg-[#e9e9e930] border w-full p-2">
                <div className="font-bold uppercase text-sm text-primary p-3">
                  Filter Product Selection
                </div>
                {PRODUCT_SURVEY.options.map((option) => (
                  <div
                    className={`p-3 flex items-center gap-2 justify-between rounded-lg cursor-pointer ${
                      currentOption === option
                        ? "bg-primary/10"
                        : "hover:bg-primary/5"
                    }`}
                    onClick={() => {
                      setCurrentOption(option);
                      setSliderValue(0);
                    }}
                  >
                    <div>{option.name}</div>
                    <div className="rounded-full p-1 text-xs w-5 h-5 flex items-center justify-center font-semibold text-primary bg-primary/10">
                      {option.products?.length ?? 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-9">
              <div className="max-w-7xl w-full">
                <div className="mx-8">
                  <Slider
                    value={sliderValue}
                    onChange={(value) => setSliderValue(value)}
                    min={0}
                    max={currentOption.products.length - 1}
                    marks={currentOption.products
                      ?.map((product, i) => ({
                        index: i,
                        name: product.name,
                      }))
                      .reduce(
                        (a, v) => ({
                          ...a,
                          [v.index]: `${v.name}`,
                        }),
                        {}
                      )}
                    step={null}
                  />
                </div>

                <div className="grid grid-cols-12 gap-10 mt-16">
                  <div className="col-span-5">
                    <div
                      className="h-80 flex items-center justify-center border rounded-md font-semibold bg-cover bg-no-repeat bg-center group"
                      style={
                        {
                          // backgroundImage: `url(${currentOption.products?.[sliderValue]?.image})`,
                        }
                      }
                    >
                      <div
                        className="w-full h-80 flex items-center justify-center rounded-md font-semibold bg-contain bg-no-repeat bg-center blur-backdrop group-hover:scale-105 transition ease"
                        style={{
                          backgroundImage: `url(${currentOption.products?.[sliderValue]?.image})`,
                        }}
                      ></div>
                    </div>

                    <div className="text-center text-xs mt-3 text-gray-400">
                      Images are for illustration purposes only, final design
                      subject to customer requirements.
                    </div>
                  </div>
                  <div className="col-span-7">
                    <div className="font-bold text-lg">
                      {currentOption.products?.[sliderValue]?.name}
                    </div>
                    <div className="text-gray-700">
                      {currentOption.products?.[sliderValue]?.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>

      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-14 md:py-24 space-y-12 px-5 md:px-0">
        <div className="w-full rounded-lg bg-background p-10 grid grid-cols-2 gap-14 justify-center text-white">
          <div className="flex flex-col gap-10 col-span-1">
            <div className="text-2xl">Our services for you</div>
            <div>
              Our service does not end with the sale or rental. The sustainable
              maintenance of your equipment is also one of our services. Our
              trained technicians ensure that our and your plastic welding
              machines are reliably maintained and repaired.
            </div>
            <div>
              <Button
                className="!font-semibold"
                buttonStyle="primary"
                label="Call Us Today"
                size="large"
                rightIcon
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                onClick={() => navigate("/contact")}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 grid-rows-2 gap-5">
            <div className="rounded-lg bg-primary w-full h-full flex flex-col items-center justify-center gap-5 p-5 group">
              <div>
                <img
                  src={maintenanceImage}
                  className="h-20 group-hover:scale-110 transition ease-in-out"
                />
              </div>
              <div className="text-lg font-bold">Maintenance service</div>
            </div>

            <div className="rounded-lg bg-primary w-full h-full flex flex-col items-center justify-center gap-5 p-5 group">
              <div>
                <img
                  src={repairImage}
                  className="h-20 group-hover:scale-110 transition ease-in-out"
                />
              </div>
              <div className="text-lg font-bold">Repair service</div>
            </div>

            <div className="rounded-lg bg-primary w-full h-full flex flex-col items-center justify-center gap-5 p-5 group">
              <div>
                <img
                  src={sparePartsImage}
                  className="h-20 group-hover:scale-110 transition ease-in-out"
                />
              </div>
              <div className="text-lg font-bold">Spare parts service</div>
            </div>

            <div className="rounded-lg bg-primary w-full h-full flex flex-col items-center justify-center gap-5 p-5 group">
              <div>
                <img
                  src={freightImage}
                  className="h-20 group-hover:scale-110 transition ease-in-out"
                />
              </div>
              <div className="text-lg font-bold">Delivery service</div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-quote-section">
        <div className="bg-background/90 h-full w-full">
          <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-40 text-center capitalize">
            <div className="text-primary font-bold tracking-widest uppercase mb-5">
              Get a Quote
            </div>

            <div className="text-white font-bold text-5xl">
              Are you prepared to{" "}
              <span className="text-primary">get your project started</span>?
            </div>
            <div className="text-white font-bold text-5xl mb-10">
              Get a quote right now!
            </div>

            <Button
              className="!font-semibold"
              buttonStyle="primary"
              label="Get Started"
              size="large"
              rightIcon
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              onClick={() => navigate("/contact")}
            />
          </div>
        </div>
      </div>

      <Contact />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsOpen(false);
            setSelectedProduct(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="grid grid-cols-5 gap-6 w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="col-span-2">
                    <img src={selectedProduct?.image} />
                  </div>
                  <div className="col-span-3">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      WP {selectedProduct?.name}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {selectedProduct?.description}
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
