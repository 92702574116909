import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";

export const ContactPage = () => {
  return (
    <div>
      <div className="pt-20 md:pt-32">
        <Contact />
      </div>
    </div>
  );
};
