import { useNavigate } from "react-router-dom";

export const NavItem = ({ label, href, active }) => {
  const navigate = useNavigate();

  return (
    <li
      className={`active:scale-95 transition ease-in-out cursor-pointer ${
        active ? "!text-primary" : "text-white hover:text-primary"
      }`}
    >
      <a
        className="transition ease-in-out flex items-center h-16 select-none gap-3 rounded-md p-2 leading-none no-underline outline-none focus-visible:ring-2 focus-visible:ring-foreground-lighter group-hover:bg-transparent text-strong hover:text-brand focus-visible:text-brand"
        onClick={() => navigate(href)}
      >
        <div className="leading-snug font-semibold">{label}</div>
      </a>
    </li>
  );
};
